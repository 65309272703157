import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: '/orgLoginGinseng/orgLogin',
    method: 'post',
    data
  })
}
//获取验证码
export function getSms(query) {
  return request({
    url: '/auth/v1/getSms',
    method: 'get',
    params: query
  })
}
//通过url查询合作伙伴信息
export function listPartnerByUrl(query) {
  return request({
    url: '/companyGinseng/listPartnerByUrl',
    method: 'get',
    params: query
  })
}
